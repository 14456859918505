import { createSlice } from "@reduxjs/toolkit";
import { signUp, login } from "./AuthThunk";
import { status } from "Constants";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    registerData: {
      status: null,
      data: {},
    },
    loggedInUser: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending.toString(), (state, action) => {
        return {
          ...state,
          registerData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(signUp.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          registerData: {
            status: status.SUCCESS,
            data: payload,
          },
        };
      })
      .addCase(signUp.rejected.toString(), (state, action) => {
        return {
          ...state,
          registerData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      })

      .addCase(login.pending.toString(), (state, action) => {
        return {
          ...state,
          loggedInUser: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(login.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          loggedInUser: {
            status: status.SUCCESS,
            data: payload || {},
          },
        };
      })
      .addCase(login.rejected.toString(), (state, action) => {
        return {
          ...state,
          loggedInUser: {
            status: status.FAILURE,
          },
        };
      });
  },
});

export default authSlice.reducer;

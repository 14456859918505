import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { LoginLayout, AppLayout } from "Layout";
import "react-toastify/dist/ReactToastify.css";
import { LocalStorageService, TokenService } from "Utils/HelperFunctions";
import FillSurvey from "Postlogin/Survey/FillSurvey";
import { FCM_TOKEN } from "Constants";
import PullToRefresh from "react-pull-to-refresh";

function App() {
  const [token, setToken] = useState(TokenService.getToken());

  useEffect(() => {
    setToken(TokenService.getToken());
  });

  // Function to handle refresh
  const handleRefresh = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        window.location.reload();
        resolve();
      }, 2000); // Simulate a network request delay
    });
  };

  if (token) {
    return (
      <BrowserRouter>
        <Suspense fallback={() => <div>Loading...</div>}>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate to="/app/postlogin/dashboard" replace={true} />
              }
            />
            <Route
              path="/app/*"
              element={
                <PullToRefresh onRefresh={handleRefresh}>
                  <AppLayout />
                </PullToRefresh>
              }
            />
            <Route
              path="*"
              element={
                <Navigate to="/app/postlogin/dashboard" replace={true} />
              }
            />
          </Routes>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Suspense>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Suspense fallback={() => <div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Navigate to="/auth" replace={true} />} />
            <Route path="/auth/*" element={<LoginLayout />} />
            {window.location.href.includes("survey-preview") ? (
              <Route
                path="/app/postlogin/survey-preview/:surveyId/:token"
                element={<FillSurvey />}
              />
            ) : (
              <Route
                path="/app/*"
                element={<Navigate to="/auth/prelogin/login" replace={true} />}
              />
            )}
            <Route
              path="*"
              element={
                <Navigate
                  to={`/auth/prelogin/login/${
                    LocalStorageService.getData(FCM_TOKEN)
                      ? LocalStorageService.getData(FCM_TOKEN)
                      : ""
                  }`}
                  replace={true}
                />
              }
            />
          </Routes>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Suspense>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;

import axios from "axios";
import { env } from "ReduxStore/EnvironmentConfig";
import { handleLogout, TokenService } from "Utils/HelperFunctions";
import { alert } from "Utils/alert";

const service = axios.create({
  baseURL: env.baseURL,
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    const jwtToken = TokenService.getToken();
    if (jwtToken) {
      config.headers = {
        Authorization: `Bearer ${jwtToken}`,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Remove token and redirect
    if (error?.response?.status) {
      // let message = "";
      if (error.response.status === 401) {
        handleLogout();
        alert.error("User Unauthorized.");
        setTimeout(() => {
          document.location.replace(
            document.location.origin + "/auth/prelogin/login"
          );
        }, 1000);
        // Handle logout
      }

      if (error.response.status === 404) {
        // message = "Not Found";
      }

      if (error.response.status === 500) {
        // message = "Error";
      }

      if (error.response.status === 508) {
        // message = "Time Out";
      }
      // ToastMessage.error("Error");
    }

    return Promise.reject(error);
  }
);

const authService = axios.create({
  baseURL: env.baseURL,
  timeout: 60000,
});

authService.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Remove token and redirect
    if (error?.response?.status) {
      // let message = "";
      if (error.response.status === 400 || error.response.status === 403) {
        // message = "Authentication failed";
        // Handle logout
      }

      if (error.response.status === 404) {
        // message = "Not Found";
      }

      if (error.response.status === 500) {
        // message = "Error";
      }

      if (error.response.status === 508) {
        // message = "Time Out";
      }
      // ToastMessage.error("Error");
    }
    console.log(error.response.data);
    alert.error(error.response?.data?.message || "Internal Server Error.");
    return Promise.reject(error.response?.data);
  }
);

export { service as postLoginService };
export { authService as preLoginService };
